<template>
  <div
      class="scroller" >
    <div ref="items"
         @scroll="scroll"
         class="items horizontal-scroll-wrapper"
         v-bind:style="{scrollSnapType: snapType}"
         v-dragscroll.x >
      <div
          v-for="(list, index) in data"
          class="item"
          v-bind:style="{cursor: selectedCursor}"
          @mousedown="mouseDown"
          @mousemove="mouseMove"
          @mouseup="mouseUp"
          @click="setSelectedIndex(index)"
          :class="{'is-active': selIndex === index}"
          :key="index">
        <slot
            :index="index"
            v-bind="list"
            :list="list"
            name="item">
          <b-image
              :src="list.image"
              v-bind="list"
          />
        </slot>
      </div>
      <div
          v-if="arrow"
          class="carousel-arrow"
          :class="{'is-hovered': settings.arrowHover}">
        <b-icon
            v-show="hasPrev"
            class="has-icons-left"
            @click.native.prevent="prev"
            :pack="settings.iconPack"
            :icon="settings.iconPrev"
            :size="settings.iconSize"
            both />
        <b-icon
            v-show="hasNext"
            class="has-icons-right"
            @click.native.prevent="next"
            :pack="settings.iconPack"
            :icon="settings.iconNext"
            :size="settings.iconSize"
            both />
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'Scroller',
  components: {
  },
  props: {
    value: {
      type: Number,
      default: 0
    },
    data: {
      type: Array,
      default: () => []
    },
    arrow: {
      type: Boolean,
      default: true
    },
    arrowHover: {
      type: Boolean,
      default: true
    },
    iconPack: String,
    iconSize: String,
    iconPrev: {
      type: String,
      default: 'chevron-left'
    },
    iconNext: {
      type: String,
      default: 'chevron-right'
    },
    breakpoints: {
      type: Object,
      default: () => ({})
    },
    selectedIndex: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      isMounted: false,
      hasPrev: false,
      hasNext: false,
      selIndex: null,
      mouseMoved: false,
      mouseDrag: false,
      pos: {
        x: Number,
        y: Number,
      },
      threshold: 10,
      selectedCursor: 'pointer',
      snapType: 'x mandatory'
    }
  },
  computed: {
    settings()
    {
      let breakpoint = this.breakpointKeys.filter((breakpoint) => {
        if (this.windowWidth >= breakpoint) {
          return true
        }
      })[0]
      if (breakpoint) {
        return {...this.$props, ...this.breakpoints[breakpoint]}
      }
      return this.$props
    },
    breakpointKeys() {
      return Object.keys(this.breakpoints).sort((a, b) => b - a)
    },
    itemWidth() {
      return this.$refs.items.querySelector('div').clientWidth;
    }
  },
  methods: {
    mouseDown(e) {
      this.pos.x = e.clientX;
      this.pos.y = e.clientY;
      this.mouseMoved = false;
      this.mouseDrag = true;
    },
    mouseMove(e) {
      this.mouseMoved = Math.abs(this.pos.x - e.clientX) > this.threshold;
      if (this.mouseMoved && this.mouseDrag && !this.$isMobile()) {
        this.selectedCursor = 'grab';
        this.snapType = 'unset';
      } else {
        this.selectedCursor = 'pointer';
      }
    },
    mouseUp(e) {
      this.mouseMoved = Math.abs(this.pos.x - e.clientX) > this.threshold;
      this.selectedCursor = 'pointer';
      this.mouseDrag = false;
      this.snapType = 'x mandatory';
    },
    setSelectedIndex(newValue) {
      if (!this.mouseMoved) {
        const oldValue = this.selIndex;
        this.selIndex = newValue;
        if(oldValue !== newValue) this.$emit('selectedindex', newValue);
      }
    },
    next() {
      this.$refs.items.scrollBy(this.itemWidth, 0);
    },
    prev() {
      this.$refs.items.scrollBy(-this.itemWidth, 0);
    },
    scroll() {
      this.hasPrev = this.$refs.items.scrollLeft !== 0;
      this.hasNext = this.$refs.items.clientWidth + this.$refs.items.scrollLeft < this.$refs.items.scrollWidth;
    }
  },
  mounted() {
    this.hasNext = (this.$refs.items.clientWidth < this.$refs.items.scrollWidth);
    this.selIndex = this.$props.selectedIndex;
  }
}
</script>
