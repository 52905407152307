<template>
  <div id="app">
    <potential ref="potential" />
    <roadshow ref="roadshow" :fix-header="fixRoadshowHeader" />
    <Summary ref="summary" :fix-header="fixSummaryHeader" />
  </div>
</template>
<script>

import Potential from './components/Potential'
import Roadshow from './components/Roadshow'
import Summary from './components/Summary'

export default {
  name: 'App',
  components: {
    Potential,
    Roadshow,
    Summary
  },
  created () {
    window.addEventListener('scroll', this.handleScroll);
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll () {
      const offset = this.$refs.potential.$el.clientHeight - this.$el.parentElement.parentElement.scrollTop - this.convertRemToPixels(3.75);
      const limit = Math.max( document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight );
      const pageOffset = document.documentElement.scrollTop + document.documentElement.clientHeight;
      console.log(pageOffset, limit);
      this.fixRoadshowHeader = offset > 0;
      this.fixSummaryHeader = !(pageOffset >= limit);
    },
    convertRemToPixels: function (rem, pixels = 0) {
      return rem * parseFloat(getComputedStyle(document.documentElement).fontSize) + pixels;
    }
  },
  data() {
    return {
      potential: 123152,
      roadshow: 96647.43,
      ergebnis: '',
      roi: '',
      fixRoadshowHeader: true,
      fixSummaryHeader: true
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0 0.75rem 0.75rem;
}
</style>


