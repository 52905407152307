<template>
  <section>
    <div class="card no-shadow">
      <div class="card-header-wrapper sticky-header">
        <MountingPortal mountTo="#roadshowHeader" append :disabled="fixHeader" >
          <div class="card-header" aria-controls="contentIdForA11y3">
            <div class="columns card-header-title">
              <b-steps :has-navigation="false" label-position="right" :type="stepClass">
                <b-step-item step="2" label="Roadshowbedarf" :clickable="false" />
              </b-steps>
              <div class="column has-no-padding has-text-right">{{ roadshowHeader }}</div>
            </div>
          </div>
        </MountingPortal>
      </div>
      <scrollama @step-enter="enterRoadshow" @step-exit="exitRoadshow" @step-progress="progressRoadshow" :offset="topSpace">
        <div class="card-content">
          <div class="block-separator-type1"></div>
          <div class="block">
            <h6 class="title is-6 has-no-bottom-margin">Fahrzeugtyp</h6>
            <scroller
                :selectedIndex="fahrzeugTyp"
                @selectedindex="setFahrzeugTyp"
                arrow
                :arrow-hover="false"
                icon-size="is-large"
                icon-pack="fas"
                :data="fahrzeugTypen">
              <template #item="list">
                <div class="card">
                  <div class="card-image">
                    <figure class="image car">
                      <img :src="list.image">
                    </figure>
                  </div>
                  <div class="card-content">
                    <div class="content">
                      <div class="item-title"><strong>{{ list.title }}</strong></div>
                      <ul class="description">
                        <li>{{ list.description1 }}</li>
                        <li>Nutzfläche {{ list.description2 }}m<sup>2</sup></li>
                        <li>{{ list.description3 }}</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div v-if="list.category !== ''" :class="list.class"><strong>{{ list.category }}</strong></div>
              </template>
            </scroller>
          </div>
          <div class="block-separator-type2"></div>
          <div class="block has-space-right">
            <h6 class="title is-6 has-no-bottom-margin">Ausstattung</h6>
            <div class="columns subtitle is-6 is-vcentered is-mobile has-no-gap">
              <div class="column">
                <div>
                  Ausstattung des Fahrzeugs (Branding, Innenausbau) mit verschiedenen Stufen der Wertigkeit
                </div>
              </div>
            </div>
            <div>
              <b-field>
                <b-radio v-model="ausstattung" native-value="1" type="is-danger" @input="changed" class="has-right-padding">Eco</b-radio>
                <b-radio v-model="ausstattung" native-value="2" type="is-danger" @input="changed" class="has-right-padding">Business</b-radio>
                <b-radio v-model="ausstattung" native-value="3" type="is-danger" @input="changed" class="has-right-padding">First</b-radio>
              </b-field>
            </div>
          </div>
          <div class="block-separator-type2"></div>
          <div class="block">
            <h6 class="title is-6 has-no-bottom-margin">Truck-Manager</h6>
            <div class="column has-no-left-padding">
              Selbstfahrer (soweit nach Fahrzeugtyp möglich) oder wird professionelles Truck-Management gewünscht?
            </div>
            <div>
              <b-field>
                <b-radio v-model="truckManager" :disabled="truckManagerDisabled" :native-value="false" type="is-danger" @input="changed(true)" class="has-right-padding">selber fahren</b-radio>
                <b-radio v-model="truckManager" :disabled="truckManagerDisabled" :native-value="true" type="is-danger" @input="changed(true)" class="has-right-padding">Truck-Manager</b-radio>
              </b-field>
            </div>
          </div>
          <div class="block-separator-type2"></div>
          <div class="block has-space-right">
            <h6 class="title is-6 has-no-bottom-margin">Region</h6>
            <div class="column has-no-left-padding">
              Erfahrungsgemäß notwendige Kilometerleistung in Abhängigkeit der mit der Tour befahrenen Region.
            </div>
            <div>
              <b-field>
                <b-radio v-model="region" native-value="0" type="is-danger" @input="changed" class="has-right-padding">D</b-radio>
                <b-radio v-model="region" native-value="1" type="is-danger" @input="changed" class="has-right-padding">D/A/CH</b-radio>
                <b-radio v-model="region" native-value="2" type="is-danger" @input="changed">EU</b-radio>
              </b-field>
            </div>
          </div>
          <div class="block-separator-type2"></div>
          <div class="block has-space-right">
            <h6 class="title is-6 has-no-bottom-margin is-not-last-child">Kosten</h6>
            <div class="columns is-mobile">
              <div class="column is-6">Mit der Umsetzung der Roadshow verbundene Kosten</div>
              <div class="column is-6 has-text-right has-no-right-padding">{{ roadshowDisplay }}</div>
            </div>
          </div>
        </div>
      </scrollama>
    </div>
  </section>
</template>

<script>
import { bus } from '@/main';
import 'intersection-observer'
import Scrollama from 'vue-scrollama'
import Scroller from './scroller/Scroller'

export default {
  name: 'Roadshow',
  props: {
    header: String,
    potentialDauer: Number,
    fixHeader: Boolean
  },
  components: {
    Scrollama,
    Scroller
  },
  mounted() {
    bus.$on('potentialchanged', (data) => {
      if (data) {
        this.dauer = data.dauer;
        this.veranstaltungenProMonat = data.veranstaltungenProMonat;
        this.changed(true);
      }
    });
  },
  methods: {
    setFahrzeugTyp: function(index) {
      this.fahrzeugTyp = index;
      this.changed();
    },
    changed: function (ohneTruckManager) {
      const lookupIndex = this.fahrzeugTypen[this.fahrzeugTyp].lookupIndex;
      console.log('lookupIndex', lookupIndex);
      console.log('lookupIndex', lookupIndex);
      this.truckManagerDisabled = !this.fahrzeugTypen[this.fahrzeugTyp].truckManager;
      if (!ohneTruckManager) this.truckManager = !this.fahrzeugTypen[this.fahrzeugTyp].truckManager;
      if (this.lookupCarModel) this.ausstattungPreis = this.lookupCarModel[lookupIndex];
      if (this.lookupTruckManager) this.truckManagerTagesPreis = this.lookupTruckManager[lookupIndex];
      if (this.lookupCarMonthlyFee) this.fahrzeugMonatsPreis = this.lookupCarMonthlyFee[this.dauer - 1][lookupIndex];
      if (this.lookupAusstattungPreisFaktor) this.ausstattungPreisFaktor = this.lookupAusstattungPreisFaktor[this.ausstattung];
      if (this.lookupRegionPreis) this.regionPreis = this.lookupRegionPreis[this.region];
      if (this.lookupRegionFaktor) this.regionFaktor = this.lookupRegionFaktor[lookupIndex];
      this.roadshow = this.dauer * this.fahrzeugMonatsPreis +
                      this.ausstattungPreis * this.ausstattungPreisFaktor +
                      (this.truckManager ? this.dauer * this.tageProMonat * this.truckManagerMonatsPreisFaktor * this.truckManagerTagesPreis : 0) +
                      this.regionPreis * this.regionFaktor * this.veranstaltungenProMonat;
      this.roadshowDisplay = new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR', maximumFractionDigits: 0}).format(this.roadshow);
      this.roadshowHeader = new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR', maximumFractionDigits: 0}).format(this.roadshow);
      this.stepClass = this.roadshowHeader == '' ? 'is-danger' : 'is-danger';
      bus.$emit('roadshowchanged', {roadshow: this.roadshow, veranstaltungenProMonat: this.veranstaltungenProMonat});
    },
    // eslint-disable-next-line no-unused-vars
    enterRoadshow: function ({element, index, direction}) {
      this.portalDisabled = false;
    },
    // eslint-disable-next-line no-unused-vars
    progressRoadshow: function ({element, index, direction}) {

    },
    // eslint-disable-next-line no-unused-vars
    exitRoadshow: function ({element, index, direction}) {
      if (direction === 'up') {
        this.portalDisabled = true;
      }
    },
    convertRemToPixels: function (rem, pixels = 0) {
      return rem * parseFloat(getComputedStyle(document.documentElement).fontSize) + pixels;
    }

  },
  data() {
    return {
      dauer: 3,
      tageProMonat: 30,
      veranstaltungenProMonat: 65,
      fahrzeugTyp: 2,
      fahrzeugMonatsPreis: 0,
      ausstattung: 1,
      ausstattungPreis: 0,
      ausstattungPreisFaktor: 1,
      truckManager: true,
      truckManagerDisabled: false,
      truckManagerTagesPreis: 0,
      truckManagerMonatsPreisFaktor: 0.857142857142857,
      region: 0,
      regionPreis: 100,
      regionFaktor: 1.19,
      roadshow: 0,
      roadshowDisplay: new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(96647.43),
      roadshowHeader: '',
      stepClass: 'is-danger',
      gallery: false,
      listDefinition:  {
        itemsToShow: 4,
        breakpoints: {
          768: {
            itemsToShow: 2
          },
          960: {
            itemsToShow: 6
          }
        }
      },
      fahrzeugTypen: [
        {
          title: 'CUBE.FE',
          image: require('@/assets/cube.fe.webp'),
          lookupIndex: 1,
          truckManager: true,
          description1: 'Messeanhänger',
          description2: '17',
          description3: '30 Minuten Aufbauzeit',
          category: 'Expomodule',
          class: 'item-description is-double'
        },
        {
          title: 'CUBE.expo',
          image: require('@/assets/cube.expo.webp'),
          lookupIndex: 0,
          truckManager: true,
          description1: 'Messeanhänger',
          description2: '22',
          description3: '40 Minuten Aufbauzeit',
          category: '',
          class: ''
        },
        {
          title: 'EXPOSER',
          image: require('@/assets/exposer.webp'),
          lookupIndex: 2,
          truckManager: true,
          description1: 'Infomobil',
          description2: '10',
          description3: '5 Minuten Aufbauzeit',
          category: 'Infomobile',
          class: 'item-description is-double'
        },
        {
          title: 'FUTURIA BOXX',
          image: require('@/assets/futuria.boxx.webp'),
          lookupIndex: 3,
          truckManager: true,
          description1: 'Infomobil',
          description2: '12',
          description3: '5 Minuten Aufbauzeit',
          category: '',
          class: ''
        },
        {
          title: 'PROMOSTAR',
          image: require('@/assets/promostar.webp'),
          lookupIndex: 6,
          truckManager: false,
          description1: 'Leichtsattelzug',
          description2: '32',
          description3: '30 Minuten Aufbauzeit',
          category: 'Showtrucks (midi)',
          class: 'item-description is-double'
        },
        {
          title: 'FUTURIA',
          image: require('@/assets/futuria.webp'),
          lookupIndex: 4,
          truckManager: false,
          description1: 'Leichtsattelzug',
          description2: '35',
          description3: '30 Minuten Aufbauzeit',
          category: '',
          class: ''
        },
        {
          title: 'SPACE-1',
          image: require('@/assets/space.1.webp'),
          lookupIndex: 7,
          truckManager: false,
          description1: 'Raummodul (L)',
          description2: '34',
          description3: '60 Minuten Aufbauzeit',
          category: 'Raummodul',
          class: 'item-description is-single'
        },
        {
          title: 'SPACE-3',
          image: require('@/assets/space.3.webp'),
          lookupIndex: 8,
          truckManager: false,
          description1: 'Raummodul (XL)',
          description2: '65',
          description3: '30 Minuten Aufbauzeit',
          category: 'Showtrucks (maxi)',
          class: 'item-description is-double'
        },
        {
          title: 'GIANT',
          image: require('@/assets/giant.webp'),
          lookupIndex: 5,
          truckManager: false,
          description1: 'Sattelzug 40t',
          description2: '63-94',
          description3: '45 Minuten Aufbauzeit',
          category: '',
          class: ''
        }
      ],
      lookupCarModel: [10700, 10700, 10540, 10540, 20020, 20020, 18820, 18820, 20020],
      lookupTruckManager: [410, 410, 400, 400, 440, 460, 430, 440, 460],
      lookupCarMonthlyFee: [[6930, 5700, 5590, 6440, 18900, 23860, 17360, 14700, 26580],
                            [5780, 4650, 4660, 5680, 16640, 19380, 13360, 12600, 22730],
                            [4800, 3950, 4000, 5200, 14960, 18680, 12370, 10950, 19520],
                            [4800, 3950, 4000, 5200, 14960, 18680, 12370, 10950, 19520],
                            [4800, 3950, 4000, 5200, 14960, 18680, 12370, 10950, 19520],
                            [3850, 3150, 3250, 4960, 13740, 16830, 9900, 9250, 16090],
                            [3850, 3150, 3250, 4960, 13740, 16830, 9900, 9250, 16090],
                            [3850, 3150, 3250, 4960, 13740, 16830, 9900, 9250, 16090],
                            [3270, 2680, 2640, 4250, 11500, 15970, 8420, 8300, 13680],
                            [3270, 2680, 2640, 4250, 11500, 15970, 8420, 8300, 13680],
                            [3270, 2680, 2640, 4250, 11500, 15970, 8420, 8300, 13680],
                            [3000, 2460, 2420, 3900, 10960, 13730, 7720, 7700, 12520]],
      lookupAusstattungPreisFaktor: [0.0, 1.0, 1.81, 2.91],
      lookupRegionPreis: [100, 200, 300],
      lookupRegionFaktor: [0.90, 0.90, 0.58, 0.68, 1.42, 1.74, 1.19, 1.08, 1.74],
      topSpace: this.convertRemToPixels(6.75, 2) + 'px',
      portalDisabled: true
    }
  }
}
</script>

<style scoped>

</style>
