<template>
  <section>
    <div class="card no-shadow">
      <div class="card-header-wrapper sticky-header">
        <div class="card-header-wrapper sticky-header" style="display: none;">
          <MountingPortal mountTo="#summaryHeader" append :disabled="fixHeader" >
            <div class="card-header" aria-controls="contentIdForA11y3">
              <div class="columns card-header-title">
                <b-steps :has-navigation="false" label-position="right" :type="stepClass">
                  <b-step-item step="3" label="Ergebnisprognose" :clickable="false" />
                </b-steps>
                <div class="column has-no-padding has-text-right">{{ summaryHeader }}</div>
              </div>
            </div>
          </MountingPortal>
        </div>
        <div class="card-header" aria-controls="contentIdForA11y3">
          <div class="columns card-header-title">
            <b-steps :has-navigation="false" label-position="right" :type="stepClass">
              <b-step-item step="3" label="Ergebnisprognose der Roadshow" :clickable="false" />
            </b-steps>
            <div class="column has-no-padding has-text-right">{{ summaryHeader }}</div>
          </div>
        </div>
      </div>
      <div class="card-content">
        <div class="block has-space-right has-no-bottom-margin" v-bind:style="{ display: showSummaryNot }">
          <div class="column has-no-left-padding">
            Ihre Roadshow kann mehr erreichen. Korrigieren Sie Ihre Annahmen mit mehr Mut. <br><br>
          </div>
          <div class="columns is-mobile hidden">
            <div class="column title is-6 has-no-bottom-margin">Ergebnis</div>
            <div class="column has-text-right has-no-right-padding">{{ ergebnis }}</div>
          </div>
        </div>
        <div class="block has-space-right" v-bind:style="{ display: showSummaryNot }">
          <div class="columns is-mobile hidden">
            <div class="column title is-6 has-no-bottom-margin">Return on Investment (ROI)</div>
            <div class="column has-text-right has-no-right-padding">{{ roi }}</div>
          </div>
        </div>
        <div class="block has-space-right has-no-bottom-margin" v-bind:style="{ display: showSummary }">
          <div class="column has-no-left-padding">
            Anhand der von Ihnen eingegebenen Parameter liefert Ihre Roadshow ein Ergebnis in Höhe von {{ ergebnis }} oder anders ausgedrückt einen ROI in Höhe von {{ roi }}.
          </div>
          <div class="columns is-mobile">
            <div class="column title is-6 has-no-bottom-margin">Ergebnis</div>
            <div class="column has-text-right has-no-right-padding"><strong>{{ ergebnis }}</strong></div>
          </div>
        </div>
        <div class="block has-space-right" v-bind:style="{ display: showSummary }">
          <div class="columns is-mobile">
            <div class="column title is-6 has-no-bottom-margin">Return on Investment (ROI)</div>
            <div class="column has-text-right has-no-right-padding"><strong>{{ roi }}</strong></div>
        </div>
      </div>
    </div>
    </div>
  </section>
</template>

<script>
import { bus } from '../main';
export default {
  name: 'Summary',
  props: {
    fixHeader: Boolean
  },
  components: {
  },
  mounted() {
    bus.$on('potentialchanged', (data) => {
      if (data) {
        this.potential = data.potential;
        this.changed();
      }
    }),
    bus.$on('roadshowchanged', (data) => {
      if (data) {
        this.roadshow = data.roadshow;
        this.changed();
      }
    })
  },
  methods: {
    changed: function () {
      if (this && this.potential) {
        if (this.roadshow) {
          this.ergebnis = new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 0
          }).format(this.potential - this.roadshow);
          this.roi = new Intl.NumberFormat('de-DE', {style: 'percent'}).format((this.potential - this.roadshow) / this.roadshow);
          this.summaryHeader = (this.potential - this.roadshow < 0) ? '' : new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
            maximumFractionDigits: 0
          }).format(this.potential - this.roadshow);
        }
      }
      this.showSummary = (this.potential - this.roadshow < 0) ? 'none' : 'block';
      this.showSummaryNot = (this.potential - this.roadshow < 0) ? 'block' : 'none';
      this.stepClass = this.showSummary === 'block' ? 'is-danger' : 'is-danger';
    }
  },
  data: function () {
    return {
      potential: 123152,
      roadshow: 96647.43,
      ergebnis: '',
      roi: '',
      summaryHeader: '',
      stepClass: 'is-danger',
      showSummary: 'block',
      showSummaryNot: 'none'
    }
  }
}
</script>
