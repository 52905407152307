<template>
  <section>
    <div class="card no-shadow">
      <div class="card-header-wrapper sticky-header">
        <MountingPortal mountTo="#potentialHeader" append >
          <div class="card-header" aria-controls="contentIdForA11y3">
            <div class="columns card-header-title">
              <b-steps :has-navigation="false" label-position="right" :type="stepClass">
                <b-step-item step="1" label="Potenzialeinschätzung" :clickable="false" />
              </b-steps>
              <div class="column has-no-padding has-text-right">{{ potentialHeader }}</div>
            </div>
          </div>
        </MountingPortal>
      </div>
      <scrollama @step-enter="enterPotential" @step-exit="exitPotential" :offset="topSpace">
        <div class="card-content">
          <div class="content">
           <div class="block-separator-type1"></div>
            <div class="block has-space-right">
              <h6 class="title is-6 has-no-bottom-margin">Dauer der Roadshow</h6>
              <div class="columns is-mobile has-no-gap">
                <div class="column">
                  Dauer der Roadshow in Monaten
                </div>
              </div>
              <b-slider class="has-slider-top-margin" v-model="dauer" :min="1" :max="12" type="is-danger" rsounded tooltip-always @input="changed" @change="changed">
                <template>
                  <b-slider-tick class="is-slider-label-left" :value="1">1&nbsp;Monat</b-slider-tick>
                  <b-slider-tick class="is-slider-label-right" :value="12">12&nbsp;Monate</b-slider-tick>
                </template>
              </b-slider>
            </div>
            <div class="block-separator-type2"></div>
            <div class="block has-space-right">
              <h6 class="title is-6 has-no-bottom-margin">Anzahl der Veranstaltungen pro Woche</h6>
              <div class="columns is-mobile has-no-gap">
                <div class="column">
                  Wie viele Veranstaltungen pro Woche. Bitte Samstag und Sonntag beachten. 3 Veranstaltungen pro Tag nur in Ausnahmefällen.
                </div>
              </div>
              <b-slider class="has-slider-top-margin" v-model="veranstaltungen" :min="1" :max="15" type="is-danger" rsounded tooltip-always @input="changed" @change="changed">
                <template>
                  <b-slider-tick class="is-slider-label-left" :value="1">1&nbsp;Veranstaltung</b-slider-tick>
                  <b-slider-tick class="is-slider-label-right" :value="15">15&nbsp;Veranstaltungen</b-slider-tick>
                </template>
              </b-slider>
            </div>
            <div class="block-separator-type2"></div>
            <div class="block has-space-right">
              <h6 class="title is-6 has-no-bottom-margin">Teilnehmer pro Veranstaltung</h6>
              <div class="columns is-mobile has-no-gap">
                <div class="column">
                  Anzahl der Teilnehmer pro Veranstaltung. Bitte die Fahrzeuggröße berücksichtigen.
                </div>
              </div>
              <b-slider class="has-slider-top-margin" v-model="teilnehmer" :min="5" type="is-danger" rsounded tooltip-always @input="changed" @change="changed">
                <template>
                  <b-slider-tick class="is-slider-label-left" :value="5">5&nbsp;Besucher</b-slider-tick>
                  <b-slider-tick class="is-slider-label-right" :value="100">100&nbsp;Besucher</b-slider-tick>
                </template>
              </b-slider>
            </div>
            <div class="block-separator-type2"></div>
            <div class="block has-space-right">
              <h6 class="title is-6 has-no-bottom-margin">Interessenten</h6>
              <div class="columns is-mobile has-no-gap">
                <div class="column">
                  Anteil der <strong>{{ Intl.NumberFormat('de-DE').format(besucher) }} Besucher</strong> in %, der als vertrieblich für Ihr Unternehmen interessant einzuschätzen ist.
                </div>
              </div>
              <b-slider class="has-slider-top-margin" v-model="kontaktRate" :min="10" type="is-danger" :custom-formatter="(val) => val + '%'" rounded tooltip-always @input="changed" @change="changed">
              <template>
                <b-slider-tick class="is-slider-label-left" :value="10">10%</b-slider-tick>
                <b-slider-tick class="is-slider-label-right" :value="100">100%</b-slider-tick>
              </template>
            </b-slider>
            </div>
            <div class="block-separator-type2"></div>
            <div class="block has-space-right">
              <h6 class="title is-6 has-no-bottom-margin">Erfolgsquote (Leads generieren)</h6>
              <div class="columns is-mobile has-no-gap">
                <div class="column">
                  Schätzen Sie potenzielle Geschäftsabschlüsse auf Basis der Kontaktrate.
                  Bei einer Erfolgsquote von <strong>{{ leadsRate }}%</strong> werden aus {{ Intl.NumberFormat('de-DE').format(kontakte) }} Interessenten <strong>{{ Intl.NumberFormat('de-DE').format(leads) }} Leads</strong> generiert.
                </div>
              </div>
              <b-slider class="has-slider-top-margin" v-model="leadsRate" :min="15" :max="80" type="is-danger" :custom-formatter="(val) => val + '%'" rounded tooltip-always @input="changed" @change="changed">
                <template>
                  <b-slider-tick class="is-slider-label-left" :value="15">15%</b-slider-tick>
                  <b-slider-tick class="is-slider-label-right" :value="80">80%</b-slider-tick>
                </template>
              </b-slider>
            </div>
            <div class="block-separator-type2"></div>
            <div class="block has-bottom-padding">
              <h6 class="title is-6 has-no-bottom-margin has-no-bottom-margin">Wert eines Leads</h6>
              <div class="columns is-desktop has-no-gap">
                <div class="column has-input-bottom-padding">
                  Geschätzter Wert pro Lead im Durchschnitt:
                </div>
                <div class="column has-no-top-padding has-no-bottom-padding">
                  <b-input custom-class="has-text-right" class="has-small-width has-currency" type="number" v-model="leadWert" @input="changed" icon-right="pencil-alt"></b-input>
                </div>
              </div>
            </div>
            <div class="block-separator-type4"></div>
            <div class="block has-space-right">
              <h6 class="title is-6 has-no-bottom-margin">Bekanntheit steigern</h6>
              <div class="columns is-desktop has-no-gap">
                <div class="column">
                  Geben Sie <strong>optional</strong> an, wie viel Ihnen die Besucher im Sinne von "Impressions" wert sind (<strong>Wert je {{ Intl.NumberFormat('de-DE').format(1000) }} Besucher</strong>):
                </div>
                <div class="column has-no-top-padding has-no-bottom-padding">
                  <b-input custom-class="has-text-right" class="has-small-width has-currency" type="number" v-model="tkp" @input="changed" icon-right="pencil-alt"></b-input>
                </div>
              </div>
            </div>
            <div class="block-separator-type4"></div>
            <div class="block has-space-right">
            <h6 class="title is-6">Potenzial</h6>
            <div class="columns subtitle is-6 is-mobile">
              <div class="column">Wert des Potenzials</div>
              <div class="column has-no-right-padding" align="right">{{ potentialDisplay }}</div>
            </div>
          </div>
          </div>
        </div>
      </scrollama>
    </div>
  </section>
</template>

<script>
import { bus } from '@/main'
import 'intersection-observer'
import Scrollama from 'vue-scrollama'
export default {
  name: 'Potential',
  props: {
    header: String
  },
  components: {
    Scrollama,
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    enterPotential: function ({element, index, direction}) {
      this.portalDisabled = false;
    },
    // eslint-disable-next-line no-unused-vars
    exitPotential: function ({element, index, direction}) {
      if (direction === 'up') {
        this.portalDisabled = true;
      }
    },
    changed: function () {
      this.veranstaltungenProMonat = this.dauer * this.wochenProMonat * this.veranstaltungen;
      this.besucher = Math.round(this.teilnehmer * this.veranstaltungenProMonat);
      this.kontakte = Math.round(this.besucher * (this.kontaktRate / 100));
      this.leads = Math.round(this.kontakte * (this.leadsRate / 100));
      this.potential =  Math.round((this.leads * this.leadWert) + (this.besucher * this.tkp / 1000));
      this.potentialDisplay = new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR', maximumFractionDigits: 0}).format(this.potential);
      this.potentialHeader = new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR', maximumFractionDigits: 0}).format(this.potential);
      this.stepClass = this.potentialHeader == '' ? 'is-danger' : 'is-danger';
      bus.$emit('potentialchanged', {dauer: this.dauer, veranstaltungenProMonat: this.veranstaltungenProMonat, potential: this.potential});
    },
    convertRemToPixels: function (rem, pixels = 0) {
      return rem * parseFloat(getComputedStyle(document.documentElement).fontSize) + pixels;
    }
  },
  data() {
    return {
      dauer: 3,
      wochenProMonat: 4.345,
      veranstaltungen: 5,
      veranstaltungenProMonat: 65,
      teilnehmer: 100,
      besucher: 6518,
      tkp: 100,
      kontaktRate: 25,
      kontakte: 750,
      leadsRate: 15,
      leads: 113,
      leadWert: 500,
      potential: 123152,
      stepClass: 'is-danger',
      potentialDisplay: new Intl.NumberFormat('de-DE', {style: 'currency', currency: 'EUR'}).format(123152),
      potentialHeader: '',
      topSpace: this.convertRemToPixels(6, 2) + 'px',
      portalDisabled: true
    }
  }
}
</script>

<style scoped>

</style>
