import Vue from 'vue'
import Buefy from 'buefy'

import App from './App.vue'
import './app.scss'

import VuetifyMoney from "vuetify-money";
Vue.use(VuetifyMoney);
export default VuetifyMoney;

import PortalVue from 'portal-vue';
Vue.use(PortalVue);

import { library } from '@fortawesome/fontawesome-svg-core';
import { faPencilAlt, faChevronDown, faChevronUp, faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueDragscroll from 'vue-dragscroll'
import VueMobileDetection from 'vue-mobile-detection'

library.add(faPencilAlt);
library.add(faChevronDown);
library.add(faChevronUp);
library.add(faAngleLeft);
library.add(faAngleRight);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.use(Buefy,  {
  defaultIconComponent: "font-awesome-icon",
  defaultIconPack: "fas",
  materialDesignIcons: false,
  customIconPacks: {
    fas: {
      sizes: {
        default: "sm",
        "is-small": "1x",
        "is-medium": "2x",
        "is-large": "3x"
      },
      iconPrefix: ""
    }
  }
})
Vue.config.productionTip = false;

Vue.use(VueDragscroll);
Vue.use(VueMobileDetection);

export const bus = new Vue();

new Vue({
  render: h => h(App),
}).$mount('#app')
